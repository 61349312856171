<template>
  <div class="excel-connect">
    <div class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>主调函数参数类型：</span>
        </div>
        <div class="text item">
          <h4>主调函数参数说明：</h4>
          <ul>
            <li><span style="color: rgb(255, 0, 0);">templateName</span>：模板名称，不带扩展名xls。</li>
            <li><span style="color: rgb(255, 0, 0);">downLoadFileName</span>：下载的文件名，带扩展名。</li>
            <li><span style="color: rgb(255, 0, 0);">saveAsFileName</span>：指定保存的文件名称，即另存为的名称。</li>
            <li><span style="color: rgb(255, 0, 0);">startExportColIndex</span>：确定结果从第几列开始输出,无需的话则为-1，列索引从0开始。</li>
            <li><span style="color: rgb(255, 0, 0);">groupColName</span>：分组列，用途：多sheet输出，如按单位输出人员名单，则可将groupColName设置为UnitName（单位的列名）。
            </li>
            <li><span style="color: rgb(255, 0, 0);">isMultiSheet</span>：是否多sheet输出，true将生成多sheet输出。</li>
            <li><span style="color: rgb(255, 0, 0);">isDownLoad</span>：是否下载，true直接下载，false返回生成的文件名。</li>
          </ul>
        </div>
      </a-card>
    </div>
    <div class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>通过excel模板导出</span>
        </div>
        <div class="text item">
          <aside>
            <a-button type="submit" status="primary" @click="exportExcel('31')">导出excel</a-button>
            <a-button type="submit" status="primary" @click="exportExcel('33')">导出excel zip</a-button>
          </aside>
          <span style="color: red; font-size: 20px">
            导出多个并压缩成zip包是参数必须使用ids且在模板xml文件中使用id接收，如果导出单个文件时参数可自定义
          </span>
          <pre>
           引入 import { exportExcel } from 'common/utils/Report/ReportExcelHelper'
           // 方法自定义
            exportExcel() {
               const param = {
               templateDir: 'template/demo/excel/ExportTable.xls', // 模板路径是从resources目录下开始
               isDownLoad: 'true', // 下载
               // ids: '1,2,3', // ids参数名固定，当导出多个时会根据ids导出一个压缩包
                id: '1', // 自定义参数，往sql中$Aid处填充数据 当为id代表下载一个文件
               saveAsFileName: '管理人员个人信息表'// 指定另存为的文件名
               }
               // 调用导出的方法
               exportExcel(param)
            }
          </pre>
        </div>
      </a-card>
    </div>
    <div class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>无Excel模板，传入SQL语句输出列表、下载</span>
        </div>
        <div class="text item">
          <aside>
            <a-button type="submit" status="primary" @click="exportExcel('11')">通过sql语句导出excel</a-button>
            <a-button type="submit" status="primary" @click="exportExcel('12')">通过sql语句导出excel(从第二列开始导出)</a-button>
          </aside>
          <pre>
              <font color="#ff00ff">const param = { </font>
                <font color="#008080">startExportColIndex</font>:-1,//输出所有的列 1冲第二列开始输出
                <font color="#008080">isDownLoad</font>:"true", //下载
                <font color="#008080">saveAsFileName</font>:"通过sql进行导出excel", //指定另存为的文件名
                <font color="red">isBySQL</font>:1,  //通过SQL导出Excel，SQL从Session中获取，Key格式：Report.Excel.ExportExcel.SQL
                <font color="red">exportExcelSQL</font>: 'select \'妻子\' cw,\'李桂花\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
                                                      ' union select \'父亲\' cw,\'张民主\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
                                                      ' union select \'母亲\' cw,\'李思思\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
                                                      ' union select \'长子\' cw,\'张建国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
                                                      ' union select \'儿子\' cw,\'张卫国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual ' +
                                                      ' union select \'女儿\' cw,\'张卫东\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual'  //sql语句
              <font color="#ff00ff">}</font>
              <font color="#008080">exportExcel(param)</font>
          </pre>
        </div>
      </a-card>
    </div>
    <div class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>支持无Excel模板，根据传入的数据DataTable进行输出列表、下载</span>
        </div>
        <div class="text item">
          <aside>
            <a-button type="submit" status="primary" @click="exportExcel('21')">根据传入DataTable导出excel</a-button>
            <a-button type="submit" status="primary" @click="exportExcel('22')">根据传入DataTable导出excel(从第二列开始导出)</a-button>
            <a-button type="submit" status="primary" @click="exportExcel('23')">根据传入DataTable导出excel(根据分组列多sheet)</a-button>
          </aside>
          <a-table
            ref="tableGrid"
            border
            resizable
            height="300"
            rowKey="cw"
            :columns="tableColumn"
            :dataSource="tableData"
          />
          <span style="color: red; font-size: 20px">
            如果对导出的列不满意可对records进行重写即可
          </span>
          <pre>
              // 获取列表选中数据
              const records = this.$refs.tableGrid.getCheckboxRecords()
              // 根据传入DataTable导出excel
              param = {
                isByDataTable: 'true', // 通过DataTable输出Excel
                startExportColIndex: '-1', // 输出DataTable中的所有列
                isDownLoad: 'true', // 是否下载
                exportExcelDataTable: records, // 数据
                saveAsFileName: '根据DataTable导出excel' // 指定另存为的文件名
              }
              exportExcel(param)
              // 根据传入DataTable导出excel(从第二列开始导出)
              param = {
                templateDir: 'demo', // 模板所在目录
                isByDataTable: 'true', // 通过DataTable输出Excel
                startExportColIndex: '1', // 从DataTable的第2列开始输出
                isDownLoad: 'true', // 是否下载
                exportExcelDataTable: records, // 数据
                saveAsFileName: '根据DataTable导出excel' // 指定另存为的文件名
              }
              exportExcel(param)
              // 根据传入DataTable导出excel(根据分组列多sheet)
              param = {
                isByDataTable: 'true', // 通过DataTable输出Excel
                startExportColIndex: '1', // 从DataTable的第2列开始输出
                groupColName: 'zzmm', // 通过政治面貌分组，不区分大小写
                isMultiSheet: 'true', // 是否多sheet输出
                isDownLoad: 'true', // 是否下载
                exportExcelDataTable: records, // 数据
                saveAsFileName: '根据DataTable导出excel' // 指定另存为的文件名
              }
              exportExcel(param)
          </pre>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>

import { exportExcel } from 'common/utils/Report/ReportExcelHelper'

export default {
  name: 'Excel',
  data() {
    return {
      fileName: '',
      tableColumn: [
        { dataIndex: 'cw', title: '称谓', sortable: true, width: 120 },
        { dataIndex: 'xm', title: '姓名', sortable: true, width: 120 },
        { dataIndex: 'csrq', title: '出生日期', sortable: true, width: 140 },
        { dataIndex: 'zzmm', title: '政治面貌', sortable: true, width: 160 },
        { dataIndex: 'gzdw', title: '工作单位、职务及国籍', sortable: true, align: 'left', headerAlign: 'center', showHeaderOverflow: true, width: 300 }
      ],
      tableData: [
        {
          cw: '妻子', xm: '李桂花', csrq: '1990-12-12', zzmm: '大学', gzdw: ''
        }, {
          cw: '父亲', xm: '张民主', csrq: '1990-12-12', zzmm: '大学', gzdw: ''
        }, {
          cw: '母亲', xm: '李思思', csrq: '1990-12-12', zzmm: '中共党员', gzdw: ''
        }, {
          cw: '长子', xm: '张建国', csrq: '1990-12-12', zzmm: '中共党员', gzdw: ''
        }, {
          cw: '儿子', xm: '张卫国', csrq: '1990-12-12', zzmm: '中共党员', gzdw: ''
        }, {
          cw: '女儿', xm: '张卫东', csrq: '1990-12-12', zzmm: '中共党员', gzdw: ''
        }
      ]
    }
  },
  methods: {
    exportExcel(type) {
      // 设置参数信息
      let param = {
        templateDir: 'template/gbxxgl/excel/yinlian-personinfo.xls', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
        // isExportMultiFile: '0', // 是否多文件输出(1或true)
        isDownLoad: 'true', // 下载
        id: '2cec710a37ac4656a5f60a514ee4afad', // 所有主键都用id传
        saveAsFileName: '管理人员个人信息表' // 指定另存为的文件名
      }
      if (type === '11') {
        param = {
          startExportColIndex: -1, // 输出所有的列
          isDownLoad: 'true', // 下载
          exportExcelSQL: 'select \'妻子\' cw,\'李桂花\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'父亲\' cw,\'张民主\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'母亲\' cw,\'李思思\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'长子\' cw,\'张建国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'儿子\' cw,\'张卫国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual ' +
              ' union select \'女儿\' cw,\'张卫东\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual',
          isBySQL: 1,
          saveAsFileName: '通过sql进行导出excel' // 指定另存为的文件名
        }
      } else if (type === '111') {
        param = {
          templateDir: 'demo', // 模板所在目录
          startExportColIndex: -1, // 输出所有的列
          exportExcelSQL: 'select \'妻子\' cw,\'李桂花\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'父亲\' cw,\'张民主\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'母亲\' cw,\'李思思\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'长子\' cw,\'张建国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'儿子\' cw,\'张卫国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual ' +
              ' union select \'女儿\' cw,\'张卫东\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual',
          isBySQL: 1, // 通过SQL导出Excel，
          saveAsFileName: '通过sql进行导出excel' // 指定另存为的文件名
        }
      } else if (type === '112') {
        if (this.fileName === '') {
          alert('尚未生成Excel文件')
          return
        }
        // exportExcelDownLoad({
        //   templateDir:"demo",//模板所在目录
        //   downLoadFileName:fileName //指定要下载的文件的名称
        // });
      } else if (type === '12') {
        param = {
          templateDir: 'demo', // 模板所在目录
          startExportColIndex: 1, // 从第2列开始输出，列索引从0开始
          isDownLoad: 'true', // 下载
          isBySQL: 1, // 通过SQL导出Excel，
          exportExcelSQL: 'select \'妻子\' cw,\'李桂花\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'父亲\' cw,\'张民主\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'母亲\' cw,\'李思思\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'长子\' cw,\'张建国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'1\' PersonID from dual ' +
              ' union select \'儿子\' cw,\'张卫国\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual ' +
              ' union select \'女儿\' cw,\'张卫东\' xm,\'1990-12-12\' csrq,\'大学\' zzmm,\'\' gzdw,\'2\' PersonID from dual',
          saveAsFileName: '通过sql进行导出excel' // 指定另存为的文件名
        }
      } else if (type === '21') {
        const records = this.$refs.tableGrid.getCheckboxRecords()
        if (records.length === 0) {
          this.$message.error('请至少选择一条数据!')
          return
        }
        param = {
          isByDataTable: 'true', // 通过DataTable输出Excel
          startExportColIndex: '-1', // 输出DataTable中的所有列
          isDownLoad: 'true', // 是否下载
          exportExcelDataTable: JSON.stringify(records), // 数据
          saveAsFileName: '根据DataTable导出excel' // 指定另存为的文件名
        }
      } else if (type === '22') {
        const records = this.$refs.tableGrid.getCheckboxRecords()
        if (records.length === 0) {
          this.$message.error('请至少选择一条数据!')
          return
        }
        param = {
          isByDataTable: 'true', // 通过DataTable输出Excel
          startExportColIndex: '1', // 从DataTable的第2列开始输出
          isDownLoad: 'true', // 是否下载
          exportExcelDataTable: JSON.stringify(records), // 数据
          saveAsFileName: '根据DataTable导出excel' // 指定另存为的文件名
        }
      } else if (type === '23') {
        const records = this.$refs.tableGrid.getCheckboxRecords()
        if (records.length === 0) {
          this.$message.error('请至少选择一条数据!')
          return
        }
        param = {
          isByDataTable: 'true', // 通过DataTable输出Excel
          startExportColIndex: '1', // 从DataTable的第2列开始输出
          groupColName: 'zzmm', // 通过政治面貌分组，不区分大小写
          isMultiSheet: 'true', // 是否多sheet输出
          isDownLoad: 'true', // 是否下载
          exportExcelDataTable: JSON.stringify(records), // 数据
          saveAsFileName: '根据DataTable导出excel' // 指定另存为的文件名
        }
      } else if (type === '31') {
        param = {
          templateDir: 'template/demo/excel/ExportTable.xls', // 模板所在目录
          saveAsFileName: '根据模板导出', // 指定另存为的文件名
          isDownLoad: 'true',
          id: '1',
          jieCi: '2019年', // 自定义参数，对应Excel模板中的$jieCi
          personCount: '99'// 自定义参数，对应Excel模板中的$personCount
        }
      } else if (type === '32') {
        param = {
          templateDir: 'template/demo/excel/ExportTable.xls', // 模板所在目录
          saveAsFileName: '根据模板导出', // 指定另存为的文件名
          isDownLoad: 'true', // 下载
          // groupColName: 'BATCHID', // 通过批次ID分组
          isMultiSheet: 'true', // 是否多sheet输出
          jieCi: '2019年', // 自定义参数，对应Excel模板中的$jieCi
          personCount: '99'// 自定义参数，对应Excel模板中的$personCount
        }
      } else if (type === '33') {
        param = {
          templateDir: 'template/demo/excel/ExportTable.xls', // 模板所在目录
          saveAsFileName: '根据模板导出', // 指定另存为的文件名
          isDownLoad: 'true',
          ids: '1,2,3',
          jieCi: '2019年', // 自定义参数，对应Excel模板中的$jieCi
          personCount: '99'// 自定义参数，对应Excel模板中的$personCount
        }
      } else if (type === '41') {
        param = {
          templateDir: 'demo', // 模板所在目录
          templateName: 'ExcelTemplate4', // 干部任免沟通名单模板
          isDownLoad: 'true', // 下载
          head: '2019年第一批次'// 自定义参数，和Excel中的$head对应
        }
      } else if (type === '51') {
        param = {
          templateDir: 'demo', // 模板所在目录
          templateName: 'yinlian-mingce', // 干部任免沟通名单模板
          isDownLoad: 'true', // 下载
          unitName: '上海分公司', // 自定义参数，和Excel中的$head对应
          ids: '1,2,3' // 自定义参数，和Excel中的$PersonIDs对应
        }
      } else if (type === '61') {
        param = {
          templateDir: 'demo', // 模板所在目录
          templateName: 'yinlian-personinfo', // 干部任免沟通名单模板
          isDownLoad: 'true', // 下载
          unitName: '上海分公司', // 自定义参数，和Excel中的$head对应
          id: '1' // 自定义参数，和Excel中的$PersonIDs对应
        }
      } else if (type === 'table') {
        param = {
          templateDir: 'demo', // 模板所在目录
          templateName: 'ExportTable', // 干部任免沟通名单模板
          isDownLoad: 'true', // 下载
          id: '1' // 自定义参数，和Excel中的$PersonIDs对应
        }
      }
      exportExcel(param)
    }
  }
}
</script>

<style lang="less" scoped>
  .excel-connect {

    h4 {
      margin: 8px 0;
      font-size: 16px;
      COLOR: #690;
    }

    ol li, ul li {
      margin-left: 2em;
      margin-bottom: 0.5em;
    }

    a {
      color: #07c;
      text-decoration: none;
      border: 0;
      background-color: transparent;
    }
  }
</style>
