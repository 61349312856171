<template>
  <div class="word-connect">
    <div class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>主调函数参数类型：</span>
        </div>
        <div class="text item">
          <pre>
              引入 import { exportWord } from 'common/utils/Report/ReportWordHelper'
              // 方法自定义
              exportWord() {
                const param = {
                  templateDir: 'template/gbxxgl/word/gbrmspb.doc', // 模板路径是从resources目录下开始
                  isDownLoad: 'true', // 下载
                  isToMultiWord：'false',
                  A00: '2cec710a37ac4656a5f60a514ee4afad', // 自定义参数，往sql中$A00处填充数据
                  saveAsFileName: '任免表文件'// 指定另存为的文件名
                }
                // 调用导出的方法
                exportWord(param)
              }
          </pre>
          <h4>主调函数参数说明：</h4>
          <ul>
            <li><span style="color: rgb(255, 0, 0);">templateDir</span>
              ：（必填，默认resource目录下开始）：使用的模板位置
              模板如gbrmspb.doc在resource/template/gbxxgl/word/目录下,既值为template/gbxxgl/word/gbrmspb.doc。
            </li>
            <li><span style="color: rgb(255, 0, 0);">isDownload</span>
              ：（必填，默认false）true：直接下载。
            </li>
            <li><span style="color: rgb(255, 0, 0);">saveAsFileName</span>
              ：（选填）生成的文件的名称，可以为空，即""。
            </li>
            <li><span style="color: rgb(255, 0, 0);">isToMultiWord</span>
              ：（选填，默认false）true：先生成多个word，再生成zip压缩包，false：直接生成一个word。
            </li>
            <li><span style="color: rgb(255, 0, 0);">A00自定义参数</span>
              ：（选填）按需添加，需和xml模板中的所用到的参数保持一致
            </li>
          </ul>
        </div>
      </a-card>
    </div>
    <div class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>测试用例目录：</span>
        </div>
        <div class="text item">
          <ul>
            <li><a href="#exam1">一: word输出</a></li>
            <li><a href="#exam2">二: 图表输出</a></li>
            <li><a href="#exam3">三: 在xml配置sql语句（先执行当前sql把sql返回值当参数使用）</a></li>
          </ul>
        </div>
      </a-card>
    </div>
    <div id="exam1" class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>word输出</span>
        </div>
        <div class="text item">
          <ul>
            <li>
              <ul>
                <li>
                  <a-button type="text" size="small" @click="exportWord('gbrmspb')">导出单人单word</a-button>
                  <a-button type="text" size="small" @click="exportWord('gbrmspb-noloop')">导出单人单word(noloop)
                  </a-button>
                </li>
                <li>
                  <a-button type="text" size="small" @click="exportWord('gbrmspb1')">导出多人多word</a-button>
                </li>
                <li>
                  <a-button type="text" size="small" @click="exportWord('gbrmspb2')">导出多人单word</a-button>
                </li>
              </ul>
            </li>
          </ul>
          <pre>
                // 单人单word
                const param = {
                  templateDir: 'template/demo/word/gbrmspb.doc',
                  isDownload: 'true',
                  A00: '9726a3b2-a17e-4d62-a8f3-2ab499a463b2',
                  saveAsFileName: '任免审批表2019'
                }
                exportWord(param)

                // 单人单word(noloop)
                const param = {
                  templateDir: 'template/demo/word/gbrmspb-noloop.doc',
                  isDownload: 'true',
                  A00: '9726a3b2-a17e-4d62-a8f3-2ab499a463b2',
                  saveAsFileName: '任免审批表2019(noloop)'
                }
                exportWord(param)

                // 多人多word
                const param = {
                  templateDir: 'template/demo/word/gbrmspb.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
                  isToMultiWord: '1', // 是否多word输出(1或true)
                  isDownload: 'true',
                  saveAsFileName: '任免审批表2019'
                }
                exportWord(param)
                // 多人单word
                const param = {
                  templateDir: 'template/demo/word/gbrmspb.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
                  isToMultiWord: '0', // 是否多word输出(1或true)
                  isDownload: 'true'
                }
                exportWord(param)
           </pre>
        </div>
      </a-card>
    </div>
    <div id="exam2" class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>图表输出</span>
        </div>
        <div class="text item">
          <ul>
            <li>
              <ul>
                <li>
                  <a-button type="text" size="small" @click="exportWord('ChartTemplate')">图标输出示例</a-button>
                </li>
              </ul>
            </li>
          </ul>
          <pre>
                // 多人单word
                const param = {
                  templateDir: 'template/demo/word/ChartTemplate.docx', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
                  isDownload: 'true',
                  unitid:"123456"
                }
                exportWord(param)
           </pre>
        </div>
      </a-card>
    </div>
    <div id="exam3" class="card_connect">
      <a-card class="box-card">
        <div slot="header" class="clearfix">
          <span>sql配置</span>
        </div>
        <div class="text item">
          <h5>自定义配置sql把当前sql执行之后的数据放到后面的sql中使用</h5>
          <br>
          <pre>

              一： 返回单个值 int类型
            <p name="main1_2" desc="">
              #set($type = $dataSource.queryForInt("SELECT type FROM A01 where A00 = '$A00' ")
              SELECT * FROM A00_TYPE where TYPE = $type
            </p>

              二： 返回单个值 String类型
            <p name="main1_2" desc="">
              #set($name = $dataSource.queryForString("SELECT A0101 FROM A01 where A00 = '$A00' ")
              SELECT * FROM A00 WHERE A0101 = $name
            </p>

              三： 返回一行数据
            <p name="main1_2" desc="">
              #set($name = $dataSource.queryForString("SELECT A0101 as name,TYPE AS type FROM A01 where A00 = '$A00' ")
              SELECT * FROM A00 WHERE A0101 = $name.name and TYPE = $name.type
            </p>

              四： 返回LIST数据
            <p name="main1_2" desc="">
              #set($A00S = $dataSource.queryForString("SELECT '9726a3b2-a17e-4d62-a8f3-2ab499a463b2' A00 from dual union select 'ef12674e-c890-4f88-8ed6-433d2018bdbf' A00  from dual")
              #foreach( $A00 in $A00S)
                  SELECT '$A00' A00 from dual
              #end

              // 解析之后的结果为
              SELECT '9726a3b2-a17e-4d62-a8f3-2ab499a463b2' A00 from dual
              SELECT 'ef12674e-c890-4f88-8ed6-433d2018bdbf' A00 from dual
            </p>
           </pre>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { exportWord } from 'common/utils/Report/ReportWordHelper'

export default {
  name: 'Word',
  methods: {
    exportWord(type) {
      let param = {
        templateDir: 'template/gbxxgl/word/gbrmspb.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
        // isToMultiWord: '0', // 是否多word输出(1或true)
        isDownLoad: 'true', // 下载
        A0101: '张三' // 自定义参数，往Word模板上的“填表人： 域«A0101»”处填充数据
      }
      switch (type) {
        case 'gbrmspb':
          param = {
            templateDir: 'template/demo/word/gbrmspb.doc',
            isDownload: 'true',
            A00: '9726a3b2-a17e-4d62-a8f3-2ab499a463b2',
            saveAsFileName: '任免审批表2019'
          }
          break
        case 'gbrmspb-noloop':
          param = {
            templateDir: 'template/demo/word/gbrmspb-noloop.doc',
            isDownload: 'true',
            A00: '9726a3b2-a17e-4d62-a8f3-2ab499a463b2',
            saveAsFileName: '任免审批表2019(noloop)'
          }
          break
        case 'gbrmspb1':
          // 多人多word
          param = {
            templateDir: 'template/demo/word/gbrmspb.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isToMultiWord: '1', // 是否多word输出(1或true)
            isDownload: 'true',
            saveAsFileName: '任免审批表2019'
          }
          break
        case 'gbrmspb2':
          // 多人单word
          param = {
            templateDir: 'template/demo/word/gbrmspb.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isToMultiWord: '0', // 是否多word输出(1或true)
            isDownload: 'true'
          }
          break
        case 'GroupDemo1':
          param = {
            templateDir: 'template/demo/word/GroupDemo1.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isToMultiWord: '0', // 是否多word输出(1或true)
            isDownload: 'true'
          }
          break
        case 'ChartTemplate':
          param = {
            templateDir: 'template/demo/word/ChartTemplate.docx', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isDownload: 'true',
            saveAsFileName: '图表输出示例',
            unitid: '123456'
          }
          break
        case 111:
          // 按模板名输出多word、打包、下载
          param = {
            templateDir: 'template/demo/word/WordTemplate1.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isToMultiWord: '1', // 多记录输出，即每个记录生成一个word，最后将多个word打包成zip
            isDownload: 'true', // 下载
            A0101: '张三' // 自定义参数，往Word模板上的“填表人： 域«A0101»”处填充数据
          }
          break
        case 12:
          // 按模板名输出单word、不打包、下载
          param = {
            templateDir: 'template/demo/word/WordTemplate1.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isDownload: 'true', // 下载
            A0101: '张三' // 自定义参数，往Word模板上的“填表人： 域«A0101»”处填充数据
          }
          break
        case 13:
          // 按模板名输出word、指定生成的文件的名称、下载
          param = {
            templateDir: 'template/demo/word/WordTemplate1.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '单人单word', // 指定生成的文件的名称
            isDownload: 'true', // 下载
            A0101: '张三' // 自定义参数，往Word模板上的“填表人： 域«A0101»”处填充数据
          }
          break
        case 14:
          // 按模板名生成word，并转换为 pdf、指定生成的文件的名称、下载
          param = {
            templateDir: 'template/demo/word/WordTemplate1.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任前公示', // 指定生成的文件的名称
            isToMultiWord: '1', // 是否多word输出
            isDownload: 'true', // 下载
            isConvertToPdf: '1' // 转为pdf
          }
          break
        case 21:
          param = {
            templateDir: 'template/demo/word/WordTemplate2.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isToMultiWord: '1', // 多word输出
            isDownload: 'true'// 下载
          }
          break
        case 22:
          param = {
            templateDir: 'template/demo/word/WordTemplate2.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isDownload: 'true' // 是否下载，1或true表示下载
          }
          break
        case 23:
          param = {
            templateDir: 'template/demo/word/WordTemplate2.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任免单', // 另存为的名称
            isDownload: 'true' // 是否下载，1或true表示下载
          }
          break
        case 24:
          param = {
            templateDir: 'template/demo/word/WordTemplate2.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任免单', // 另存为的名称
            isToMultiWord: '1', // 多word输出
            isDownload: 'true', // 是否下载，1或true表示下载
            isConvertToPdf: '1'// 是否转换成pdf，1或true表示转换
          }
          break
        case 31:
          param = {
            templateDir: 'template/demo/word/WordTemplate3.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            isDownload: 'true'
          }
          break
        case 32:
          param = {
            templateDir: 'template/demo/word/WordTemplate3.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任前公示',
            isDownload: 'true'
          }
          break
        case 33:
          param = {
            templateDir: 'template/demo/word/WordTemplate3.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任前公示',
            isDownload: 'true',
            isConvertToPdf: '1'
          }
          break
        case 41:
          param = {
            templateDir: 'template/demo/word/WordTemplate4.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            templateName: 'WordTemplate4', // 任前公示模板
            isDownload: 'true', // 下载
            AreaName: '京州'// 自定义参数，替换word模板中的域«AreaName»
          }
          break
        case 42:
          param = {
            templateDir: 'template/demo/word/WordTemplate4.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任前公示',
            isDownload: 'true'
          }
          break
        case 43:
          param = {
            templateDir: 'template/demo/word/WordTemplate4.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            saveAsFileName: '任前公示',
            isDownload: 'true',
            isConvertToPdf: '1'
          }
          break
        default:
          param = {
            templateDir: 'template/demo/word/gbrmspb_ewm.doc', // 模板默认放到template下，如果放到template下的其它文件夹下，则需传此参数
            templateName: 'gbrmspb_ewm',
            isToMultiWord: '0', // 是否多word输出(1或true)
            isDownload: 'true'
          }
      }
      exportWord(param)
    }
  }
}
</script>

<style scoped lang="less">
  .word-connect {
    h4 {
      margin: 8px 0;
      font-size: 16px;
      COLOR: #690;
    }

    ol li, ul li {
      margin-left: 2em;
      margin-bottom: 0.5em;
    }

    a {
      color: #07c;
      text-decoration: none;
      border: 0;
      background-color: transparent;
    }
  }
</style>
