/*
 * Word输出
 *
 * 参数说明：
 *   isToMutiWord:true先生成word，再生成压缩包，false直接生成一个word
 * 	 isDownLoad:true直接下载，false返回生成的文件名
 * 	 isConvertToPdf:true将生成的word转为pdf
 * 	 isToMutiWord:true先生成word，再生成压缩包，false直接生成一个word
 * 	 isDownLoad:true直接下载，false返回生成的文件名
 * 	 isConvertToPdf:true将生成的word转为pdf
 * 	  自定义参数
 * */
// import { previewData } from '@/api/system/report'
// import qs from 'qs'
import {req} from 'common/api/index'
const exportUrl = '/report/exportWord/export'
const exportOnlineDocUrl = '/report/onlineDoc/open'

// 生成Word，并下载
function exportDownLoad(params) {
  // window.open(process.env.VUE_APP_BASE_API + exportUrl + '?' + qs.stringify(params, { indices: false }))

  // 使用 form 表单模拟提交
  const _reportForm = document.createElement('form')
  document.body.appendChild(_reportForm)// 加入到body中
  _reportForm.method = 'post'
  _reportForm.action = process.env.VUE_APP_BASE_API + exportUrl
  _reportForm.target = '_blank'

  if (typeof params === 'object') {
    for (const key in params) { // 遍历json对象的每个key/value对
      const paraElement = document.createElement('input')
      paraElement.setAttribute('name', key)
      paraElement.setAttribute('type', 'hidden')
      paraElement.setAttribute('value', params[key])
      _reportForm.appendChild(paraElement)
    }
    _reportForm.submit()
  }
}

// 生成Word，返回生成的文件的名称
export function exportWord(params) {
  params.exportType = 1
  let fileName = ''
  if (typeof params === 'object') {
    if (params.isDownLoad === 'true' || params.isDownLoad === '1') {
      exportDownLoad(params)
      return
    } else {
      req('custom_' + exportUrl, params).then(response => {
        fileName = response.data.fileName
        if (!fileName && response.data.code === 'bop.report.onlydownload=true') {
          params.isDownLoad = 'true'
          exportDownLoad(params)
          return
        }
        if (params.isPreview) {
          window.open(process.env.VUE_APP_BASE_API + exportOnlineDocUrl + '?fileType=.doc&fileName=' + fileName)
        }
      }).catch(() => {
        fileName = ''
      })
    }
  }
  return fileName
}

// 选择输出方式(多文件、单文件)
export function exportWordSelectOutType(params, width, height, ondestroy) {
  const previewDocBody = document.documentElement || document.body
  // eslint-disable-next-line no-unused-vars
  let previewWidth = parseInt(previewDocBody.clientWidth * 0.6)
  // eslint-disable-next-line no-unused-vars
  let previewHeight = parseInt(previewDocBody.clientHeight * 0.6)

  previewWidth = typeof (width) === 'undefined' ? previewWidth : width
  previewHeight = typeof (height) === 'undefined' ? previewHeight : height

  // eslint-disable-next-line no-unused-vars
  const _onload = function() { // 弹出页面加载完成
    const iframe = this.getIFrameEl()
    // eslint-disable-next-line no-unused-vars
    const data = {}
    // 调用弹出页面方法进行初始化
    iframe.contentWindow.setData(params)
  }

  // eslint-disable-next-line no-unused-vars
  let _ondestroy = function(action) { // 弹出页面关闭前
    if (action === 'ok') { // 如果点击“确定”
      // eslint-disable-next-line no-unused-vars
      const iframe = this.getIFrameEl()
      // 获取选中、编辑的结果
      // var data = iframe.contentWindow.GetData();
      // data = mini.clone(data); //必须。克隆数据。
    }
  }
  if (typeof (ondestroy) === 'function') {
    _ondestroy = ondestroy
  }

  // eslint-disable-next-line new-cap,no-undef
  const diag = new zDialog()

  diag.Width = 370

  diag.Height = 120

  diag.Title = '请选择文件输出方式'

  diag.InnerHtml = '<div style="text-align:center;font-size:14px;padding-top:20px;"><table ><tr><td style="text-align:left;"><span style="margin-left:30px;">单文件输出：所有人在一个word文件中显示</span></td></tr><tr><td style="text-align:left;"><span style="margin-left:30px;">多文件输出：每人一个word文件，以干部姓名命名</span></td></tr></table><br/><input type="radio" value="1" name="exportWordType" checked="checked" style="cursor:pointer"/><span>单文件输出</span><input type="radio" value="2" name="exportWordType" style="margin-left:15px;cursor:pointer"/><span>多文件输出</span></div>'

  diag.OKEvent = function() {
    const radios = document.getElementsByName('exportWordType')
    let value = 0
    for (let i = 0; i < radios.length; i++) {
      if (radios[i].checked === true) {
        value = radios[i].value
        console.log(value)
        switch (value + '') {
          case '1':
            // 单文件word输出(下载)
            params.isToMultiWord = '0' // 是否多word输出(1或true)
            params.isDownLoad = '1'
            // params.A00 = "9726a3b2-a17e-4d62-a8f3-2ab499a463b2"
            break
          case '2':
            // 多文件word输出
            params.isToMultiWord = '1' // 是否多word输出(1或true)
            params.isDownLoad = '1'
            break
          default:
            alert('请重新操作。')
        }
        exportWord(params)
        diag.close()
      }
    }
  }// 点击确定后调用的方法

  diag.show()
}

// 在线预览
export function exportWordPreview(params) {
  params.isDownLoad = false
  params.isPreview = true
  exportWord(params)
}

export function exportWordMC(params) {
  // eslint-disable-next-line no-undef,no-unused-vars
  const win = mini.open({
    id: 'myWin',
    title: '信息确认',
    allowResize: true,
    allowDrag: true,
    showCollapseButton: true,
    showMaxButton: false,
    showModal: true,
    width: 400,
    height: 300,
    url: '/report/word/ExportWord/bzmcOptions',
    onload: function() {
      const iframe = this.getIFrameEl()
      const data = params
      // 调用弹出页面方法进行初始化
      iframe.contentWindow.setParams(data)
    },
    ondestroy: function(action) { // 弹出页面关闭前
      if (action === 'ok') { // 如果点击“确定”
        const iframe = this.getIFrameEl()
        // 获取选中、编辑的结果
        let data = iframe.contentWindow.getParams()
        for (const key in data) {
          console.log(key + ':' + data[key])
        }
        // eslint-disable-next-line no-undef
        data = mini.clone(data) // 必须。克隆数据。

        exportWord(data)
      }
    }
  })
}
