<template>
  <div class="report-container">
    <a-tabs class="border-card">
      <a-tab-pane  key="1" tab="首要配置说明">
        <div class="card_connect">
          <a-card class="box-card">
            <div slot="header" class="clearfix">
              <span>report配置：</span>
            </div>
            <div class="text item">
              <pre>
                  配置文件位置 web/src/main/resources/config/application-system.yml
                  bdp-file:
                    resources: d:/resources
                  说明
                    resources参数是指向模板资源文件总路径所在位置，如resources目录在d盘下 resources的值就为d:/resources
                    resources总目录下的个别目录说明
                      template 导出的模板路径
                      temp 临时存放路径
                      license 授权文件路径
              </pre>
            </div>
          </a-card>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="word参数说明">
        <Word />
      </a-tab-pane>
      <a-tab-pane key="3" tab="excel方法参数说明">
        <excel />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import Word from './word'
import excel from './excel'

export default {
  name: 'Index',
  components: { Word, excel },
  data() {
    return {
      activeName: 'first'
    }
  }
}
</script>
<style lang="less">
  .report-container {
    /*overflow: auto;*/
    height: 100%;
    .ant-tabs {
      height: 100%;
    }
    .ant-tabs-content {
      height: ~"calc(100% - 60px)";
    }
    .ant-tabs-tabpane {
      height: 100%;
      overflow: auto;
    }
    .ant-tabs-nav-wrap {
      background: #fff;
    }
    /*.border-card {*/
    /*  height: 100%;*/
    /*  .card_connect{*/
    /*    margin: 10px 0px;*/
    /*  }*/
    /*  .el-tabs__content {*/
    /*    overflow-y: scroll;*/
    /*    height: 100%;*/
    /*  }*/
    /*}*/
    /*.ant-tabs-tabpane {*/
    /*  height: 100%;*/
    /*}*/
    /*span {*/
    /*  font-size: 16px;*/
    /*}*/

    /*.disabled {*/
    /*  pointer-events: none;*/
    /*}*/

    /*pre {*/
    /*  background: #f6f6f6;*/
    /*  border: #eee solid 1px;*/
    /*  border-radius: 0.5em;*/
    /*}*/

    /*.text {*/
    /*  font-size: 14px;*/
    /*}*/

    /*.item {*/
    /*  margin-bottom: 18px;*/
    /*}*/

    /*.clearfix:before,*/
    /*.clearfix:after {*/
    /*  display: table;*/
    /*  content: "";*/
    /*}*/

    /*.clearfix:after {*/
    /*  clear: both*/
    /*}*/
  }
</style>
